<template>
	<EditorObjectPin @done="onDone"></EditorObjectPin>
</template>
<script>
import EditorObjectPin from '../room/editorObjectPin';

export default {
	components: { EditorObjectPin },
	methods: {
		onDone({ floorId }) {
			// console.log('floorId:');
			// console.log(floorId);
			this.$router.replace({ name: 'floorDetail', params: { id: floorId } });
		},
	},
};
</script>

<style scoped></style>
